import React, { useState, useContext, useEffect } from "react";
import {
  Col,
  Button,
  Row,
  Card,
  Table,
  CardBody,
  Input,
  ButtonGroup,
  UncontrolledAlert,
  Alert as Alert2,
} from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { api, constants, UserContext, filterHelpers } from "../utils";
import {
  FilterSelect,
  FilterSwitch,
  Loader
} from "../components";
import makeAnimated from "react-select/animated";

const { TEMPOS, RESTS } = constants;

const defaultRow = {
  id: 0,
  phase: {},
  period: {},
  workout: {},
  sets: 0,
  reps: 0,
  tempo: {},
  rest: null,
};

const DEFAULT_FILTERS = [
  { filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only" },
];

export default function WorkoutBook(props) {
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const userCtx = useContext(UserContext);
  const [isBusy, setIsBusy] = useState(false);
  const [filterDisplayText, setFilterDisplayText] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [totalPageCount, setTotalPageCount] = useState(null);
  const [list, setList] = useState([]);
  const [original, setOriginal] = useState(null);
  const [message, setMessage] = useState(null);
  const [buildingHeaders, setBuildingHeaders] = useState(null);
  const [prestine, setPrestine] = useState(null);
  const [programToSave, setProgramToSave] = useState(null);
  const [preEdit, setPreEdit] = useState(false);
  const [orgsList, setOrgsList] = useState([]);
  const [messages, setMessages] = useState([]);
  const [manyOptions, setManyOptions] = useState({
    MOVEMENT: [],
    PHASE: [],
    PERIOD: [],
    WORKOUT: [],
    TEMPO: [],
    REST: [],
  });
  const [orgId, setOrgId] = useState(parseInt(userCtx.currentUser.orgId, 10));

  function loadOrgs() {
    setIsBusy(true);
    api
      .post(`OrgAdmin/GetOrgSimpleList`, { ActiveOnly: true })
      .then((r) => {
        if (r.data) {
          let result = _.map(r.data.message, x => ({
            label: x.name,
            value: x.id
          }));
          if (!_.includes(userCtx.currentUser.roleTypeIdList, constants.ROLE_IDS.SYSADMIN)) {
            result = _.filter(result, x => x.value === userCtx.currentUser.orgId);
          }
          setOrgsList(result);
        }
      })
      .finally(() => setIsBusy(false));
  }

  function loadOrgReferenceData() {
    if (!orgId || isBusy) return;
    setIsBusy(true);
    api
      .post(`WorkoutAdmin/GetProgramBuilderLists/${orgId}`)
      .then((r) => {
        if (r.data) {
          const { movementList, periodList, phaseList, workoutList } = r.data;
          if (movementList && periodList && phaseList && workoutList) {
            setManyOptions({
              MOVEMENT: movementList,
              PHASE: phaseList,
              PERIOD: periodList,
              WORKOUT: workoutList,
              TEMPO: TEMPOS,
              REST: RESTS,
            });
          }
          getPrograms();
        }
      })
      .finally(() => setIsBusy(false));
  }

  useEffect(() => {
    //if admin of admins show all else show only one
    loadOrgs();
  }, []);

  useEffect(() => {
    loadOrgReferenceData();
  }, [orgId]);

  useEffect(() => {
    getPrograms();
  }, [filters]);

  useEffect(() => {
    let filterDisplay = "";
    if (!filters || !filters.length || filters.length === 0) {
      return filterDisplay;
    }
    filterDisplay = _.chain(filters)
      .map((x) => {
        return x.value !== null || x.values || x.startDate || x.endDate
          ? x.filteredDisplay
          : null;
      })
      .filter((x) => x !== null && x !== undefined)
      .value()
      .join(" | ");
    setFilterDisplayText(filterDisplay !== "" ? ` - ${filterDisplay}` : "");
  }, [filters]);

  function createPayload() {
    let payload = {
      pageNumber: 1,
      orgId: orgId
    };
    _.each(filters, (filter) =>
        (payload[filter.filterName] = _.includes(filter.filterName, "Id")
          ? filter.value.value
          : filter.value)
    );
    return payload;
  }

  function getPrograms(forceUpdate = false) {
    if (!forceUpdate && !orgId || isBusy) return;
    setIsBusy(true);
    api
      .post(`WorkoutAdmin/PagedPrograms`, createPayload())
      .then((r) => {
        if (r.data) {
          const { listResult } = reStructureList(r.data);
          setList({ list: listResult });
          setOriginal(r.data);
        }
      })
      .finally(() => setIsBusy(false));
  };

  function clearFilterSet() {
    setFilterDisplayText("");
    setFilters([]);
  }

  function onFilterChange(changedFilter) {
    const filterChanges = filterHelpers.getFilters(filters, changedFilter);
    setFilters((prev) => {
      if (prev) {
        prev = filterChanges;
      }
      return prev;
    });
  }

  function reStructureList(list) {
    let result = {
      listResult: [],
      headersResult: [],
      totalMoves: {},
    };

    for (let i = 0; i < list.length; i++) {
      const { categories, orgWorkout } = list[i];
      let sortedCategories = [];
      if (categories.length) {
        sortedCategories = _.orderBy(categories, ["seq"]);
      }

      let baseRow = Object.assign({}, defaultRow);
      baseRow.id = orgWorkout.id;
      baseRow.programBuilderVm = list[i];
      baseRow.phase = {
        label: orgWorkout.phaseName,
        value: orgWorkout.phaseId,
      };
      baseRow.period = {
        label: orgWorkout.periodName,
        value: orgWorkout.periodId,
      };
      baseRow.workout = {
        label: orgWorkout.orgWorkoutName,
        value: orgWorkout.orgWorkoutId,
      };
      baseRow.tempo = {
        label: orgWorkout.tempoName,
        value: orgWorkout.tempoId,
      };
      baseRow.rest = orgWorkout.isStatic
        ? { label: "Active", value: 1, definition: true }
        : { label: "Not Active", value: 0, definition: false };
      baseRow.sets = `${orgWorkout.setMin}-${orgWorkout.setMax}`;
      baseRow.reps = `${orgWorkout.repMin}-${orgWorkout.repMax}`;
      if (sortedCategories.length > 0) {
        let maxColumns = 0; // needed to determine cols in headers
        for (let i = 0; i < sortedCategories.length; i++) {
          const { category, movements } = sortedCategories[i];
          const { header, repsSubheader, setsSubheader, seq, id } = category;
          const myHeaderKey = `workoutheader${seq}`;
          const mySetsSubheaderKey = `setssubheader${seq}`;
          const myRepsSubheaderKey = `repssubheader${seq}`;
          if (baseRow[myHeaderKey]) continue; // eliminates duplicates
          if (movements.length > maxColumns) {
            maxColumns = movements.length;
          }

          if (seq) {
            baseRow[myHeaderKey] = { categoryId: id, label: header };
            baseRow[mySetsSubheaderKey] = setsSubheader
              ? { categoryId: id, label: setsSubheader }
              : { categoryId: id, label: "" };
            baseRow[myRepsSubheaderKey] = repsSubheader
              ? { categoryId: id, label: repsSubheader }
              : { categoryId: id, label: "" };
            baseRow[`totalMoves${seq}`] = maxColumns;
            _.map(movements, (x, index) => {
              const { movementId, name } = x;
              const movementKey = x.seq;
              return (baseRow[`movement-${seq}.${movementKey}`] = {
                ...x,
                value: movementId,
                label: name,
                categoryId: id,
              });
            });
            //clean code to determine the largest number of cols possible for each category
            const keyName = `totalMoves${seq}`;
            if (!result.totalMoves[keyName]) {
              result.totalMoves[keyName] = maxColumns;
            } else if (result.totalMoves[keyName] < maxColumns) {
              result.totalMoves[keyName] = maxColumns;
            }
          }
        }
      }
      result.listResult.push(baseRow);
      // this is a bad idea I need a better way to handle this, what if they have six categories or seven?
      // essentially i'm using the row properties to make the headers, to accesssing them later can be simple
      // but I need the row with the most headers to make the layout for the rest
      if (result.headersResult.length === 0 && sortedCategories.length >= 5) {
        const Headers = _.reject(Object.keys(baseRow), function (o) {
          return (
            _.includes(o, "totalMoves") ||
            _.includes(o, "id") ||
            _.includes(o, "movement-") ||
            _.includes(o, "programBuilder")
          );
        });
        // here when you return that object format add some special number to it
        result.headersResult = _.map(Headers, function (x, index) {
          return { name: x.toUpperCase(), seq: index };
        });
      }
    }
    const { listResult, headersResult, totalMoves } = result;
    for (var z in totalMoves) {
      const total = totalMoves[z];
      const movesToAdd = [];
      const seq = z.slice(-1);
      for (let i = 0; i < total; i++) {
        movesToAdd.push({
          name: `MOVEMENT-${seq}.${i + 1}`,
          seq: 0,
        });
      }
      setBuildingHeaders((prev) => {
        prev = headersResult;
        let startIdx =
          _.findIndex(prev, function (x) {
            return x.name === `REPSSUBHEADER${seq}`;
          }) + 1;
        if (startIdx != 0) {
          prev.splice(startIdx, 0, ...movesToAdd, {
            name: (
              <span>
                <FontAwesomeIcon icon={"plus"} /> Add Movement
              </span>
            ),
            label: `movement-${seq}.${total + 1}`,
            seq: 0,
          });
        }
        _.map(prev, (x, idx) => (x.seq = idx + 1));
        return prev;
      });
    }
    result = {
      listResult: listResult,
      totalMoves: totalMoves,
    };

    return result;
  }

  function validate() {
    let warnings = [];
    if (
      programToSave.orgWorkout.phaseId === 0 ||
      !programToSave.orgWorkout.phaseName
    ) {
      warnings.push("You are not allowed to save a program without a phase");
    }
    if (
      programToSave.orgWorkout.periodId === 0 ||
      !programToSave.orgWorkout.periodName
    ) {
      warnings.push("You are not allowed to save a program without a period");
    }
    if (
      programToSave.orgWorkout.orgWorkoutId === 0 ||
      !programToSave.orgWorkout.orgWorkoutName
    ) {
      warnings.push("You are not allowed to save a program without a workout");
    }
    programToSave.categories = _.map(programToSave.categories, (x) => {
      x.movements = _.reject(x.movements, (m) => {
        return m.movementId === 0 && m.name === "";
      });
      return x;
    });
    return warnings;
  }

  const ConfirmUpdate = () => {
    if (!programToSave) return;
    setPreEdit(false);
    const warnings = validate();

    if (warnings && warnings.length) {
      setMessages(warnings);
      return;
    } else {
      setMessage(null);
      setMessages([]);
    }
    if (!programToSave) return;
    setIsBusy(true);
    api.post("WorkoutAdmin/SaveProgram", programToSave).then((r) => {
      if (r.data && r.data.success) {
        getPrograms(true);
        setPrestine(null);
        setSelectedRow(null);
        setProgramToSave(null);
        setMessage({
          flavor: "success",
          text: "Your changes were successfully saved",
        });
      } else {
        setMessage({
          flavor: "danger",
          text: "An error occurred while attempting to save your changes"
        });
      }
      setTimeout(() => {
        setMessage(null);
      }, 10000);
    })
    .finally(() => setIsBusy(false));
  };

  function EditMode(row) {
    if (selectedRow != row && prestine != null) CancelUpdate();
    if (row && preEdit) {
      const { categories, orgWorkout } = row.programBuilderVm;
      let lastCategory = { seq: 0, header: "", subHeader: "" };
      if (categories.length != 0) {
        _.map(categories, (x) => {
          const { category, movements } = x;
          if (category.seq > lastCategory.seq) {
            lastCategory.seq = category.seq;
            lastCategory.header = category.header;
            lastCategory.subHeader = category.subHeader;
          }
        });
      }

      let results = {
        lastCategoryInfo: lastCategory,
        isEmptyList: categories.length === 0 ? true : false,
      };
      row["lastCategoryInfo"] = results.lastCategoryInfo;
      row["isEmptyList"] = results.isEmptyList;
      setPrestine(list);
      setSelectedRow(row);
      setProgramToSave(row.programBuilderVm);
      setPreEdit(!preEdit);
    } else {
      return;
    }
  }

  function CancelUpdate() {
    setPreEdit(false);
    setSelectedRow(null);
    setProgramToSave(null);
    setList(prestine);
  }

  function onOrgChange(newOrg) {
    setOrgId(newOrg.value);
    setFilters(DEFAULT_FILTERS);
  }

  const onChangeField = (
    fieldName,
    value,
    selectMvment,
    objToReplace,
    MinOrMax
  ) => {
    const cloneFieldName = _.cloneDeep(fieldName);
    const cloneValue = _.cloneDeep(value);
    // destructure the field name to find the categoryId
    // what if they delete all the movements
    let titleAndSeq = selectMvment ? fieldName.split("-") : "";
    let number = selectMvment ? titleAndSeq[1] : "";
    let catSeqAndMoveSeq = selectMvment ? number.split(".") : "";
    const categorySeq = selectMvment ? parseInt(catSeqAndMoveSeq[0]) : "";
    const movementSeq = selectMvment ? parseInt(catSeqAndMoveSeq[1]) : "";
    let objToReplaceIdIfNULL = 0;
    const { programBuilderVm } = selectedRow;
    const { categories } = programBuilderVm;
    _.map(categories, (x) => {
      const { category, movements } = x;
      if (category.seq === categorySeq && objToReplaceIdIfNULL === 0) {
        objToReplaceIdIfNULL = category.id;
      }
      return x;
    });

    if (selectMvment) {
      setProgramToSave((prev) => {
        if (prev) {
          const { categories } = prev;
          let copyCategories = _.cloneDeep(categories);

          const result = _.map(copyCategories, (x) => {
            const { category, movements } = x;
            if (!objToReplace) {
              if (category.seq === categorySeq) {
                x.movements.push({
                  seq: movementSeq,
                  movementId: value.value,
                  name: value.label,
                });
              }
            } else {
              let copyMovements = _.cloneDeep(movements);
              if (category.id === objToReplace.categoryId) {
                const idx = _.findIndex(
                  copyMovements,
                  (x) => x.seq === objToReplace.seq
                );
                x.movements[idx] = {
                  seq: objToReplace.seq,
                  movementId: value.categoryId ? value.movementId : value.value,
                  name: value.categoryId ? value.name : value.label,
                };
              }
            }
            return x;
          });
          prev.categories = result;
        }
        return prev;
      });

      value = {
        ...value,
        categoryId: objToReplace
          ? objToReplace.categoryId
          : objToReplaceIdIfNULL,
        movementId: value ? value.value : 0,
        name: value ? value.label : "",
        seq: objToReplace ? objToReplace.seq : movementSeq,
      };
    } else {
      setProgramToSave((prev) => {
        if (prev) {
          const { orgWorkout } = prev;
          if (
            fieldName === "period" ||
            fieldName === "phase" ||
            fieldName === "workout" ||
            fieldName === "tempo"
          ) {
            const property =
              fieldName === "workout" ? _.capitalize(fieldName) : fieldName;
            fieldName === "workout"
              ? (prev.orgWorkout[`org${property}Name`] = value
                  ? value.label
                  : "")
              : (prev.orgWorkout[`${property}Name`] = value ? value.label : "");
            fieldName === "workout"
              ? (prev.orgWorkout[`org${property}Id`] = value ? value.value : 0)
              : (prev.orgWorkout[`${property}Id`] = value ? value.value : 0);
          } else if (fieldName === "rest") {
            prev.orgWorkout.isStatic = value ? value.definition : false;
          } else if (_.includes(fieldName, "header")) {
            const { categories } = prev;
            let copyCategories = _.cloneDeep(categories);
            if (_.isEmpty(copyCategories)) {
              const seq = parseInt(fieldName.slice(-1));
              copyCategories.push({
                category: {
                  id: 0,
                  seq: seq,
                  header: value,
                  repsSubheader: "",
                  setsSubheader: "",
                },
                movements: [],
              });
              prev.categories = copyCategories;
              objToReplace = { categoryId: 0, label: value };
            } else {
              if (objToReplace === undefined) {
                const seq = parseInt(fieldName.slice(-1));
                copyCategories.push({
                  category: {
                    id: 0,
                    seq: seq,
                    header: value,
                    repsSubheader: "",
                    setsSubheader: "",
                  },
                  movements: [],
                });
                prev.categories = copyCategories;
                objToReplace = { categoryId: 0, label: value };
              } else {
                const result = _.map(copyCategories, (x) => {
                  const seq = parseInt(fieldName.slice(-1));
                  const { category } = x;
                  if (
                    category.id === objToReplace.categoryId ||
                    seq === category.seq
                  ) {
                    _.includes(fieldName, "reps")
                      ? (x.category.repsSubheader = value)
                      : (x.category.header = value);
                    _.includes(fieldName, "sets")
                      ? (x.category.setsSubheader = value)
                      : (x.category.header = value);
                  }
                  return x;
                });
                prev.categories = result;
              }
            }
          } else {
            const property = _.camelCase(fieldName);
            prev.orgWorkout[cloneFieldName] = parseInt(cloneValue);
          }
          return prev;
        }
      });
    }

    if (MinOrMax) {
      function HandleMinMax(someName, someValue) {
        const prefix = someName.slice(0, 3) + "s";
        const suffix = someName.slice(3);
        const min = 0;
        const max = 1;
        let minMax = selectedRow[prefix];
        let bothValues = minMax.split("-");
        if (suffix === "Min") {
          bothValues[min] = someValue;
        } else {
          bothValues[max] = someValue;
        }
        //assign the final values
        someName = prefix;
        someValue = bothValues.join("-");

        return [someName, someValue];
      }
      const result = HandleMinMax(fieldName, value);
      fieldName = result[0];
      value = result[1];
    }

    let newRow = {
      ...selectedRow,
      [fieldName]: _.includes(fieldName, "header")
        ? { ...objToReplace, label: value }
        : value,
    };
    setSelectedRow(newRow);
  };

  return (
    // <DesktopContainer screenName={"WorkoutBook"}>
    <Row className="workoutBook-wrapper">
      <Col>
        {/* highest level before container level */}
        <Row className="workoutBook-title-header mb-5">
          <Col xs="2">
            <Row className="workoutBook-title p-3">
              <Col>
                <span>WORKOUT BUILDER</span>
              </Col>
            </Row>
          </Col>
          {message || messages.length != 0 ? (
            <Col xs="6" style={{ marginLeft: "48vh", position: "fixed" }}>
              {message ? (
                <Alert2
                  className={message.flavor}
                  style={{ marginTop: "15px" }}
                >
                  {message.text}
                </Alert2>
              ) : null}
              {messages
                ? messages.map((text, index) => {
                    return (
                      <UncontrolledAlert
                        key={`b-${index}`}
                        className={"alert-danger"}
                        style={{ marginTop: "15px" }}
                      >
                        {text}
                      </UncontrolledAlert>
                    );
                  })
                : null}
            </Col>
          ) : isBusy ? (
            <Col
              xs="3"
              style={{
                marginLeft: "11vh",
                position: "fixed",
                paddingTop: "30px",
              }}
            >
              <Loader />
            </Col>
          ) : null}

          {/*<Col className="position-relative float-right">*/}
          <div className="workoutbook-buttons-wrapper">

            <div className="workoutbook-buttons-right">
              <ButtonGroup>
                <Button
                  className="header-button-add"
                  onClick={() => CancelUpdate()}
                >
                  <FontAwesomeIcon icon={"minus"} />
                </Button>
                <Button
                  className={"header-button-add"}
                  onClick={() => setPreEdit(!preEdit)}
                >
                  <FontAwesomeIcon icon={"edit"} />
                </Button>
                <Button
                  className="header-button-add"
                  onClick={() => ConfirmUpdate()}
                >
                  <FontAwesomeIcon icon={"check"} />
                </Button>
              </ButtonGroup>
            </div>
          </div>
          {/*</Col>*/}
        </Row>

        <Row className="workoutBook-filters-wrapper">
          <Card className="workout-filters-cardBody">
            <CardBody className="p-3">
              <Row>
                <Col>
                  <Row>
                    <Col xs="12">
                      <span title="Toggle filter display" className="filter">
                        <FontAwesomeIcon icon="filter" /> Filters
                      </span>
                      <span
                        className="filter-display"
                        title={filterDisplayText}
                      >
                        {filterDisplayText}
                      </span>
                      {filters && filters.length && (
                        <Button
                          className="projectDangerOutline ml-4 xs"
                          size="sm"
                          style={{ marginTop: "-6px", marginBottom: "4px" }}
                          onClick={clearFilterSet}
                        >
                          <FontAwesomeIcon
                            icon="backspace"
                            className="mr-2 ml-0 mt-0 mb-0"
                          />{" "}
                          Clear
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {orgId
                    ? null
                    : (<Alert2 className="warning" style={{ margin: "8px" }}>Select an Organization</Alert2>)
                  }
                  <Row>
                    <Col>
                      <div className="filter-definition-caption">
                        Organization
                      </div>
                      <span>
                        <Select
                          closeMenuOnSelect={true}
                          isMulti={false}
                          components={makeAnimated()}
                          options={orgsList}
                          onChange={onOrgChange}
                          isClearable={true}
                          value={_.find(orgsList, (org) => org.value === orgId)}
                          onBlurResetsInput={false}
                          onSelectResetsInput={false}
                          onCloseResetsInput={false}
                          classNamePrefix="react-select"
                        />
                      </span>
                    </Col>
                    <Col>
                      <FilterSelect
                        filterName="PhaseId"
                        displayName="Phase"
                        value={filterHelpers.currentFilterValue(
                          filters,
                          "PhaseId"
                        )}
                        onChangeCallback={(e) => onFilterChange(e)}
                        options={manyOptions ? manyOptions.PHASE : []}
                      />
                    </Col>
                    <Col>
                      <FilterSelect
                        filterName="PeriodId"
                        displayName="Period"
                        value={filterHelpers.currentFilterValue(
                          filters,
                          "PeriodId"
                        )}
                        onChangeCallback={(e) => onFilterChange(e)}
                        options={manyOptions ? manyOptions.PERIOD : []}
                      />
                    </Col>
                    <Col>
                      <FilterSelect
                        filterName="OrgWorkoutId"
                        displayName="Workout"
                        value={filterHelpers.currentFilterValue(
                          filters,
                          "OrgWorkoutId"
                        )}
                        onChangeCallback={(e) => onFilterChange(e)}
                        options={manyOptions ? manyOptions.WORKOUT : []}
                      />
                    </Col>
                    <Col>
                      <FilterSwitch
                        filterName="ActiveOnly"
                        displayName="Active Only"
                        value={filterHelpers.currentFilterValue(
                          filters,
                          "ActiveOnly"
                        )}
                        onChangeCallback={(e) => onFilterChange(e)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>

        <Row className="workoutBook-table-wrapper">
          <Card className="workoutBook-card mb-3 mr-3">
            <CardBody className="p-0">
              <Table className="workoutBookTable">
                <thead>
                  <tr className="workoutBook-table-header">
                    {buildingHeaders && buildingHeaders.length &&
                      _.map(buildingHeaders, (x, index) => {
                        let rowKey = `row-${x.name}`;
                        if (_.isObject(x.name) || !x.name) {
                          if (x.label && !_.isObject(x.label)) {
                            rowKey = `row-LBL-${x.label}`;
                          } else if (x.seq) {
                            rowKey = `row-SEQ-${x.seq}`;
                          } else {
                            rowKey = `row-RND-${Math.random()}`;
                          }
                        }
                        return <th key={rowKey}>{x.name}</th>;
                      })}
                  </tr>
                </thead>
                <tbody>
                  {!isBusy && list && list.list && list.list.length &&
                    _.map(list.list, (x, index) => {
                      // const rowKey = `row-${(x.id ? "ID" + x.id : Math.random())}`;
                      const rowKey = 'row-RND' + Math.random();
                      return (
                          <tr key={rowKey}
                            onClick={() => EditMode(x)}
                            className={
                              selectedRow && selectedRow.id === x.id
                                ? "workout-book-selected"
                                : ""
                            }
                          >
                              {_.map(buildingHeaders, (hdr, idx) => {
                                const lowerCaseKeyName = _.toLower(hdr.name);
                                const tdKey = 'RND' + Math.random();
                                // let tdKey = `td${rowKey}${lowerCaseKeyName}`;
                                // if (_.isObject(hdr.name) || !hdr.name) {
                                //   if (hdr.label && !_.isObject(hdr.label)) {
                                //     tdKey = `td${rowKey}LBL${hdr.label}`;
                                //   } else if (hdr.seq) {
                                //     tdKey = `td${rowKey}SEQ${hdr.seq}`;
                                //   } else {
                                //     tdKey = `td${rowKey}RND${Math.random()}`;
                                //   }
                                // }
                                let value = "";
                                if (
                                  _.includes(hdr.name, "MOVEMENT-") ||
                                  _.includes(hdr.name, "REPSSUBHEADER") ||
                                  _.includes(hdr.name, "SETSSUBHEADER") ||
                                  _.includes(hdr.name, "HEADER") ||
                                  hdr.name === "PHASE" ||
                                  hdr.name === "PERIOD" ||
                                  hdr.name === "WORKOUT" ||
                                  hdr.name === "TEMPO" ||
                                  hdr.name === "REST"
                                ) {
                                  value = x[lowerCaseKeyName]
                                    ? x[lowerCaseKeyName].label
                                    : "";
                                } else {
                                  value = x[lowerCaseKeyName];
                                }
                                return (<td key={tdKey}>{value}</td>);
                              })}
                          </tr>
                      );
                    })}
                  <tr>
                    {buildingHeaders && buildingHeaders.length &&
                      _.map(buildingHeaders, (x, index) => {
                        const lowerCaseKeyName = _.toLower(x.name);
                        const lowerCaseKeyLabel = _.toLower(x.label);
                        return _.includes(x.name, "MOVEMENT-") ||
                          x.label ||
                          x.name === "PHASE" ||
                          x.name === "PERIOD" ||
                          x.name === "WORKOUT" ||
                          x.name === "REST" ||
                          x.name === "TEMPO" ? (
                          _.includes(x.name, "MOVEMENT-") || x.label ? (
                            <td width={"200px"}>
                              <Select
                                name={x.name}
                                options={
                                  manyOptions &&
                                  selectedRow &&
                                  (selectedRow && !x.label
                                    ? // this was painful
                                      parseInt(
                                        x.name.split("-")[1].split(".")[0]
                                      ) <
                                      (selectedRow
                                        ? selectedRow.lastCategoryInfo?.seq + 1
                                        : 0)
                                    : true)
                                    ? manyOptions.MOVEMENT
                                    : []
                                }
                                onChange={(selected) =>
                                  onChangeField(
                                    x.label ? x.label : lowerCaseKeyName,
                                    selected,
                                    true,
                                    x.label
                                      ? selectedRow[lowerCaseKeyLabel]
                                      : selectedRow[lowerCaseKeyName]
                                  )
                                }
                                isClearable={true}
                                onBlurResetsInput={false}
                                onSelectResetsInput={false}
                                onCloseResetsInput={false}
                                value={
                                  selectedRow
                                    ? selectedRow[lowerCaseKeyName]
                                    : null
                                }
                                menuPlacement={"top"}
                              />
                            </td>
                          ) : (
                            <td width={"245vm"}>
                              <Select
                                name={x.name}
                                options={
                                  manyOptions && selectedRow
                                    ? manyOptions[x.name]
                                    : []
                                }
                                onChange={(selected) =>
                                  onChangeField(lowerCaseKeyName, selected)
                                }
                                isClearable={true}
                                onBlurResetsInput={false}
                                onSelectResetsInput={false}
                                onCloseResetsInput={false}
                                value={
                                  selectedRow
                                    ? selectedRow[lowerCaseKeyName]
                                    : null
                                }
                                menuPlacement={"top"}
                              />
                            </td>
                          )
                        ) : x.name === "SETS" || x.name === "REPS" ? (
                          <td width="150vh" className="pl-1">
                            <Row>
                              <Col className="pr-0">
                                <Input
                                  disabled={!selectedRow}
                                  maxLength={2}
                                  type="text"
                                  name={x.name === "SETS" ? "setMin" : "repMin"}
                                  value={
                                    selectedRow
                                      ? selectedRow[
                                          _.lowerCase(
                                            x.name === "SETS" ? "SETS" : "REPS"
                                          )
                                        ].split("-")[0]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    onChangeField(
                                      e.target.name,
                                      e.target.value,
                                      null,
                                      null,
                                      true
                                    );
                                  }}
                                />
                              </Col>
                              <Col
                                xs={2}
                                className="px-0 text-center"
                                style={{ fontSize: "20px" }}
                              >
                                -
                              </Col>
                              <Col className="pl-0">
                                <Input
                                  disabled={!selectedRow}
                                  maxLength={2}
                                  type="text"
                                  name={x.name === "SETS" ? "setMax" : "repMax"}
                                  value={
                                    selectedRow
                                      ? selectedRow[
                                          _.lowerCase(
                                            x.name === "SETS" ? "SETS" : "REPS"
                                          )
                                        ].split("-")[1]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    onChangeField(
                                      e.target.name,
                                      e.target.value,
                                      null,
                                      null,
                                      true
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </td>
                        ) : _.includes(x.name, "SUB") ? (
                          <td className="pl-1">
                            <Input
                              disabled={
                                !selectedRow || !selectedRow[lowerCaseKeyName]
                              }
                              type="text"
                              name={x.name}
                              value={
                                selectedRow
                                  ? _.includes(x.name, "HEADER")
                                    ? selectedRow[lowerCaseKeyName]
                                      ? selectedRow[lowerCaseKeyName].label
                                      : ""
                                    : selectedRow[lowerCaseKeyName]
                                  : ""
                              }
                              onChange={(e) =>
                                _.includes(x.name, "HEADER")
                                  ? onChangeField(
                                      _.toLower(e.target.name),
                                      e.target.value,
                                      false,
                                      selectedRow[lowerCaseKeyName]
                                    )
                                  : onChangeField(
                                      _.toLower(e.target.name),
                                      e.target.value
                                    )
                              }
                            ></Input>
                          </td>
                        ) : (
                          <td className="pl-1">
                            <Input
                              disabled={
                                !selectedRow ||
                                parseInt(x.name.slice(-1)) >
                                  (selectedRow
                                    ? selectedRow.lastCategoryInfo?.seq + 1
                                    : 0)
                              }
                              type="text"
                              name={x.name}
                              value={
                                selectedRow
                                  ? _.includes(x.name, "HEADER")
                                    ? selectedRow[lowerCaseKeyName]
                                      ? selectedRow[lowerCaseKeyName].label
                                      : ""
                                    : selectedRow[lowerCaseKeyName]
                                  : ""
                              }
                              onChange={(e) =>
                                _.includes(x.name, "HEADER")
                                  ? onChangeField(
                                      _.toLower(e.target.name),
                                      e.target.value,
                                      false,
                                      selectedRow[lowerCaseKeyName]
                                    )
                                  : onChangeField(
                                      _.toLower(e.target.name),
                                      e.target.value
                                    )
                              }
                            ></Input>
                          </td>
                        );
                      })}
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Row>
        {/* highest level before container level */}
      </Col>
    </Row>
    // </DesktopContainer>
  );
}
